import config from 'config.js';

const platformClient = window.require("platformClient");

const client = platformClient.ApiClient.instance;

export const configureSDK = (region, token) => {
    client.setEnvironment(region);
    client.setAccessToken(token);
};

export const getCurrentUser = async () => {
    const usersApi = new platformClient.UsersApi();
    return await usersApi.getUsersMe();
};

export const getOrganization = async () => {
    const organizationApi = new platformClient.OrganizationApi();
    return await organizationApi.getOrganizationsMe();
}

export const getIntegrationType = async () => {
    const integrationsApi = new platformClient.IntegrationsApi();
    return integrationsApi.getIntegrations();
}

export const createRoles = () => {

}

export const createOauthClient = () => {
}

export const addUserToRole = () => {
}

export const createUserGroup = () => {

}

export const isAppExisting = async () => {
    return false;
}

export const validateProduct = async () => {
    const integrations = await getIntegrationType();
    const integration = integrations.entities.filter((item) => item.integrationType.id === config.intergrationId)
    return integration.length > 0;
}

export default {
    configureSDK,
    getCurrentUser,
    getOrganization,
    validateProduct,
    isAppExisting
}