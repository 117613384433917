export const headerTitle = "Genesys Cloud Integration Bridge";


const provisioningInfo = {
    role:
    {
        name: headerTitle,
        description: 'Generated role for Genesys Cloud Integration Bridge',
        permissionPolicies: [
            { // permission to allow backend to retrieve all integration types in validate org
                domain: 'integrations',
                entityName: 'integration',
                actionSet: ["view"],
                allowConditions: false
            },
            {
                domain: "authorization",
                entityName: "role",
                actionSet: ["view"],
                allowConditions: false
            }]
    }
    ,
    group: {
        name: headerTitle,
        description: 'Generated group for Genesys Cloud Integration Bridge',
        type: 'official',
        visibility: 'public',
        rulesVisible: true
    },
    oauth: {
        name: headerTitle,
        description: `Generated OAuth Client that is used by the application backend`,
        authorizedGrantType: 'CLIENT-CREDENTIALS',
        accessTokenValiditySeconds: 86400,
    }
};

const baseConfiguration = {
    app: {
        solutionKey: 'integration-bridge',
        solutionName: 'Genesys Cloud Integration Bridge',
    },
    client: 'a1049a75-6d9a-4dcc-80d5-f7131d0a9baa',
    apiEndpoint: {
        dev: 'https://dev.api.ignite.genesyspsdevops-dev.com',
        sandbox: 'https://test.api.ignite.genesyspsdevops-dev.com',
        production: 'https://api.ignite.genesyspsdevops.com'
    },
    intergrationId: {
        dev: 'premium-app-example',
        sandbox: 'premium-app-example',
        production: 'premium-app-example' // TO-DO
    },
    provisioningInfo: provisioningInfo
}


const development = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.dev,
    intergrationId: baseConfiguration.intergrationId.dev,
    provisioningInfo: provisioningInfo
}

const sandbox = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.sandbox,
    intergrationId: baseConfiguration.intergrationId.sandbox,
    provisioningInfo: provisioningInfo
}

const production = {
    app: {
        ...baseConfiguration.app
    },
    purecloud: {
        client: baseConfiguration.client,
    },
    product: baseConfiguration.product,
    apiEndpoint: baseConfiguration.apiEndpoint.production,
    intergrationId: baseConfiguration.intergrationId.production,
    provisioningInfo: provisioningInfo
}


const env = process.env.REACT_APP_CUSTOM_ENV && process.env.REACT_APP_CUSTOM_ENV.length > 0 ? process.env.REACT_APP_CUSTOM_ENV.trim() : '';
const build = process.env.REACT_APP_BUILD_VERSION && process.env.REACT_APP_BUILD_VERSION.length > 0 ? process.env.REACT_APP_BUILD_VERSION.trim() : '';
console.log('Found an issue, or need to report a bug. Please reach out to praveen.kasturi@genesys.com');
console.log('Application Environment: %o', env);
console.log('Application Version: %o', build);

let config
switch (env) {
    case 'production': config = production; break;
    case 'development': config = development; break;
    case 'sandbox': config = sandbox; break;
    default: break;
}

export default config