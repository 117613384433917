import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import PrivateSection from 'routes/PrivateSection';
import PublicRoutes from 'routes/PublicRoutes';

import { getStorageItem } from 'services/applicationStorage';


function Routes() {
    const { pathname } = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [width, height] = useWindowSize();
    const isAccessToken = getStorageItem('purecloud-csp-token', true, sessionStorage);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return isAccessToken ? <PrivateSection /> : <PublicRoutes />;
}

export default Routes;
