import React from 'react';
import { Row } from 'react-bootstrap';
import { createUseStyles, useTheme } from 'react-jss';
import * as IconLogo from '../../resources/gcib.jpg';


const useStyles = createUseStyles((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 32,
        marginRight: 32,
    },
    icon: {
        marginRight: 12,
        width: '100px',
        height: '100px',
        maxWidth: '100%',
        maxHeight: '100%'
    },
    title: {
        ...theme.typography.cardTitle,
        color: theme.color.grayishBlue,
        opacity: 0.7,
        marginLeft: 12,
    }
}));

function LogoComponent() {
    const theme = useTheme();
    const classes = useStyles({ theme });
    return (
        <Row className={classes.container} horizontal='center' vertical='center'>
            <img src="/gcib.jpg" alt="Icon" className={classes.icon} />
            <span className={classes.title}>Integration Bridge</span>
        </Row>
    );
}

export default LogoComponent;
