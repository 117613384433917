import React, { useEffect, Fragment } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// Util
import { getParameterByName } from '../services/utils';
import { setStorageItem, getStorageItem, removeStorageItem } from 'services/applicationStorage';

import gCloud from 'services/gCloud'

const Main = ({ }) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const purecloudInitialization = async () => {
            let env = new URLSearchParams(location.search).get('environment');

            if (!env) {
                env = 'mypurecloud.com';
            }

            setStorageItem('purecloud-csp-env', env, true, sessionStorage);
            console.log('Main.purecloud.init:', env);

            if (getParameterByName('access_token')) {
                setStorageItem('purecloud-csp-token', getParameterByName('access_token'), true, sessionStorage);
            }

            const envFromSession = getStorageItem('purecloud-csp-env', true, sessionStorage);
            const envFromSessionSanitized = typeof (envFromSession) === 'string' && envFromSession.trim().length > 0 ? envFromSession.trim() : '';
            const envToken = getStorageItem('purecloud-csp-token', true, sessionStorage);
            const envTokenSanitized = typeof (envToken) === 'string' && envToken.trim().length > 0 ? envToken.trim() : '';

            if (envToken) {
                try {

                    // //Configure SDk
                    // gCloud.configureSDK(envFromSessionSanitized, envTokenSanitized)
                    const isAppExisting = await gCloud.isAppExisting();

                    if (!isAppExisting) {
                        console.log("isAppExisting : ", isAppExisting)
                        gCloud.configureSDK(envFromSessionSanitized, envTokenSanitized)
                        navigate('/appfoundry')
                    } else {
                        navigate('/configuration')
                    }
                } catch (error) {
                    if (error.status === 401 && error.code === 'bad.credentials') {
                        //handle expired token
                        removeStorageItem('purecloud-csp-token', true, sessionStorage);
                        console.log('Invalid token - Redirecting to login page');
                        navigate('/login')
                    } else {
                        navigate('/unauthorized', error.message)
                    }
                }
            }
            else navigate('/login')
        };

        purecloudInitialization()
        // eslint-disable-next-line
    }, [])

    return (<Fragment></Fragment>)
}

export default Main