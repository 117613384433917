export default {
    // private
    configuration: '/configuration',
    licesening: '/licensing',
    swagger: '/swagger',
    help: '/help',
    audit: '/audit',
    appfoundry: '/appfoundry',

    // auth
    login: '/login'
};
