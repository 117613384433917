export function convertSlugToUrl(slug, parameters) {
    let url = slug;
    Object.entries(parameters).forEach(([key, value]) => {
        url = url.replace(`:${key}`, value);
    });
    return url;
}

export function validateIPAddress(value) {
    // Regular expression pattern for IP or CIDR address validation
    const ipCidrRegex =
        /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\/([0-9]|[1-2][0-9]|3[0-2]))?$/;

    // Check if the value matches the IP or CIDR address pattern
    const isValidIP = ipCidrRegex.test(value);

    return isValidIP;
}
