import { useEffect, useState } from 'react';

export default function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        // Add a throttling mechanism to avoid excessive updates
        const handleResize = () => {
            clearTimeout(timeout);
            timeout = setTimeout(updateSize, 200);
        };

        let timeout;
        window.addEventListener('resize', handleResize);
        updateSize();

        return () => {
            clearTimeout(timeout);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return size;
}
