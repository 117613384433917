import React, { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import LoadingComponent from 'components/loading';

const ConfgiurationComponent = lazy(() => import('./configuration'));
const LiceseningComponent = lazy(() => import('./licesening'));
const SwaggerComponent = lazy(() => import('./swagger'));
const AuditComponent = lazy(() => import('./audit'));
const AppfoundryComponent = lazy(() => import('./appfoundry'));

function PrivateRoutes() {
    return (
        <Suspense fallback={<LoadingComponent loading />}>
            <Routes>
                <Route exact path={SLUGS.configuration} element={<ConfgiurationComponent />} />
                <Route exact path={SLUGS.licesening} element={<LiceseningComponent />} />
                <Route exact path={SLUGS.swagger} element={<SwaggerComponent />} />
                <Route exact path={SLUGS.audit} element={<AuditComponent />} />
                <Route exact path={SLUGS.appfoundry} element={<AppfoundryComponent />} />
                <Route path="*" element={<Navigate to={SLUGS.configuration} />} />
            </Routes>
        </Suspense>
    );
}

export default PrivateRoutes;
