import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import { IconAgents, IconArticles, IconIdeas, IconLogout, IconSettings, IconTickets } from 'assets/icons';
import { convertSlugToUrl } from 'resources/utilities';
import LogoComponent from './LogoComponent';
import Menu from './MenuComponent';
import MenuItem from './MenuItemComponent';

const useStyles = createUseStyles({
    separator: {
        borderTop: ({ theme }) => `1px solid ${theme.color.lightGrayishBlue}`,
        marginTop: 16,
        marginBottom: 16,
        opacity: 0.06,
    },
    logoContainer: {
        paddingTop: 30,
        paddingBottom: 30,
    },
    menuItems: {
        paddingTop: 10,
    },
    versionText: {
        fontSize: 14,
        color: '#777',
        textAlign: 'center',
        marginTop: 16,
    },
});

function SidebarComponent() {
    const navigate = useNavigate();
    const theme = useTheme();
    const classes = useStyles({ theme });
    const isMobile = window.innerWidth <= 1080;

    function onClick(slug, parameters = {}) {
        navigate(convertSlugToUrl(slug, parameters));
    }

    const getAppVersion = () => {
        return process.env.REACT_APP_BUILD_VERSION;
    }

    return (
        <Menu isMobile={isMobile}>
            <div className={classes.logoContainer}>
                <LogoComponent />
            </div>
            <div className={classes.menuItems}>
                <MenuItem
                    id={SLUGS.configuration}
                    title="Configuration"
                    icon={IconSettings}
                    onClick={() => onClick(SLUGS.configuration)}
                />
                <MenuItem
                    id={SLUGS.licesening}
                    title="Licensing"
                    icon={IconAgents}
                    onClick={() => onClick(SLUGS.licesening)}
                />
                <MenuItem
                    id={SLUGS.swagger}
                    title="Swagger"
                    icon={IconArticles}
                    onClick={() => onClick(SLUGS.swagger)}
                />
                <MenuItem
                    id={SLUGS.help}
                    title="Help"
                    icon={IconIdeas}
                    onClick={() => onClick(SLUGS.help)}
                />
                <div className={classes.separator} />
                <MenuItem
                    id={SLUGS.audit}
                    title="Audit Logs"
                    icon={IconTickets}
                    onClick={() => onClick(SLUGS.audit)}
                />
                Version: {getAppVersion()}
                <div className={classes.versionText}>Version: {getAppVersion()}</div>
            </div>
        </Menu>
    );
}

export default SidebarComponent;
