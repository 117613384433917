import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import SLUGS from 'resources/slugs';
import Main from 'components/Main';

const PureCloudLogin = lazy(() => import('components/login/purecloud/PureCloudLogin'));
const Help = lazy(() => import('./help'));

function PublicRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Main />} />
            <Route path={SLUGS.login} element={<Suspense fallback={<div>Loading...</div>}><PureCloudLogin /></Suspense>} />
            <Route exact path={SLUGS.help} element={<Suspense fallback={<div>Loading...</div>}><Help /></Suspense>} />
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
}

export default PublicRoutes;
