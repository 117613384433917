import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';

const STATUS = {
    COLLAPSED: 'COLLAPSED',
    PRE_EXPANDED: 'PRE_EXPANDED',
    EXPANDED: 'EXPANDED',
    PRE_COLLAPSED: 'PRE_COLLAPSED'
};

function CollapsibleContent({
    children,
    expanded,
    style,
    transitionDuration,
    transitionTimingFunction,
    onTransitionStart,
    onTransitionEnd,
    ...others
}) {
    const contentContainerRef = useRef(null);
    const [status, setStatus] = useState(expanded ? STATUS.EXPANDED : STATUS.COLLAPSED);
    const [maxHeight, setMaxHeight] = useState(0);

    useEffect(() => {
        if (status === STATUS.EXPANDED || status === STATUS.PRE_EXPANDED) {
            setMaxHeight(contentContainerRef.current.scrollHeight);
        } else if (status === STATUS.COLLAPSED || status === STATUS.PRE_COLLAPSED) {
            setMaxHeight(0);
        }
    }, [status]);

    useEffect(() => {
        if (expanded) {
            if (status === STATUS.COLLAPSED || status === STATUS.PRE_COLLAPSED) {
                setStatus(STATUS.PRE_EXPANDED);
            } else if (status === STATUS.PRE_EXPANDED) {
                setTimeout(() => setStatus(STATUS.EXPANDED), 800);
            }
        } else if (!expanded) {
            if (status === STATUS.EXPANDED || status === STATUS.PRE_EXPANDED) {
                setStatus(STATUS.PRE_COLLAPSED);
            } else if (status === STATUS.PRE_COLLAPSED) {
                setTimeout(() => setStatus(STATUS.COLLAPSED), 100);
            }
        }
    }, [expanded, status]);

    useEffect(() => {
        // Reset the component's state when the expanded prop changes
        setStatus(expanded ? STATUS.EXPANDED : STATUS.COLLAPSED);
    }, [expanded]);

    return (
        <Collapsible
            open={expanded}
            transitionTime={parseInt(transitionDuration, 10)}
            easing={transitionTimingFunction}
            onOpening={onTransitionStart}
            onOpened={onTransitionEnd}
            onClosing={onTransitionStart}
            onClosed={onTransitionEnd}
            trigger={<div style={style}>{children}</div>}
            {...others}
        >
            <div
                ref={contentContainerRef}
                style={{
                    maxHeight,
                    overflow: 'hidden',
                    transitionProperty: 'max-height',
                    transitionDuration,
                    transitionTimingFunction
                }}
            >
                {children}
            </div>
        </Collapsible>
    );
}

CollapsibleContent.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
        .isRequired,
    expanded: PropTypes.bool,
    onTransitionStart: PropTypes.func,
    onTransitionEnd: PropTypes.func,
    style: PropTypes.object,
    transitionDuration: PropTypes.string,
    transitionTimingFunction: PropTypes.string
};

CollapsibleContent.defaultProps = {
    transitionDuration: '.425s',
    transitionTimingFunction: 'ease-in-out'
};

export default CollapsibleContent;
